<template>
  <page-layout>
    <v-card color="secondary">
      <v-tabs v-model="tab" background-color="tabber" color="primary" grow>
        <v-tab> Kredi </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 30px 0;
            "
          >
            <v-container>
              <v-select
                v-model="selectedFirm"
                :items="firms"
                label="Firmalar"
                item-value="_id"
                item-text="name"
                style="width: 100%; marginbottom: 10px"
                outlined
                persistent-hint
                @change="changeFirm"
              ></v-select>
            </v-container>
            <div
              v-if="
                (firms.length === 0 || (selectedFirm && test.length === 0)) &&
                !showTable
              "
            >
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <div v-else-if="!selectedFirm">
              <p style="font-style: italic">Lütfen Bir Firma Seçiniz</p>
            </div>

            <div v-else style="width: 90%">
              <v-data-table
                :headers="headers"
                :items="test"
                :loading="isLoading"
                item-key="firmName"
                multi-sort
                class="elevation-2"
              >
                <template v-slot:[`item._id`]="{ item }">
                  <v-dialog
                    v-model="dialogContour"
                    max-width="750px"
                    :retain-focus="false"
                  >
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Kredi Talebi</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container class="grey lighten-5">
                          <v-col md="12" lg="12" xl="12">
                            <v-card class="pa-1" outlined shaped>
                              <v-card-title>
                                {{ dialog.firmName }} / {{ dialog.testName }}
                              </v-card-title>
                              <v-card-text>
                                <v-text-field
                                  v-model="dialog.requestContour"
                                  label="Talep Miktarı"
                                  outlined
                                ></v-text-field>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="red darken-1"
                          text
                          @click="closeDialogContour"
                        >
                          İptal Et
                        </v-btn>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="saveDialogContourRequest"
                        >
                          Kaydet
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <button
                    class="dialogButton"
                    @click="showDialogContourRequest(item)"
                  >
                    Kredi Talebi
                  </button>
                </template>
              </v-data-table>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </page-layout>
</template>

<script>
import axios from '@axios';
import PageLayout from '@/components/PageLayout.vue';

export default {
  name: 'Contour',
  components: { PageLayout },
  data: () => ({
    dialogContour: false,
    tab: null,
    isLoading: true,
    firms: [],
    tests: [],
    test: [],
    showTable: false,
    selectedFirm: null,
    dialog: {
      firmName: null,
      firmId: null,
      testId: null,
      testName: null,
      requestContour: 0
    },
    headers: [
      {
        text: 'Test Adı',
        value: 'testName',
        align: 'start',
        sortable: false
      },
      {
        text: 'Toplam Kredi',
        value: 'maxCount',
        align: 'center',
        sortable: false
      },
      {
        text: 'Kullanılan Kredi',
        value: 'usedCount',
        align: 'center',
        sortable: false
      },
      {
        text: 'Kalan Kredi',
        value: 'remainingContour',
        align: 'center',
        sortable: false
      },
      {
        text: 'İşlemler',
        value: '_id',
        align: 'end',
        sortable: false
      }
    ]
  }),
  watch: {
    dialogContour(val) {
      val || this.closeDialogContour();
    }
  },
  mounted() {
    this.listFirm();
  },
  methods: {
    async listFirm() {
      await axios
        .get('/list-firm')
        .then((response) => {
          this.isLoading = false;
          this.firms = response.data.result;
          this.firms.push({ name: 'boss', _id: 1 });
        })
        .catch((error) => this.$toast.error(error.toString()));
    },
    async listTest(firmId) {
      await axios
        .get(`/list-used-firm-tests/${firmId}`)
        .then((response) => {
          this.isLoading = false;
          this.tests = response.data;
        })
        .catch((error) => this.$toast.error(error.toString()));
    },
    async changeFirm(e) {
      this.showTable = false;
      this.selectedFirm = e;
      this.test = [];
      this.dialog.firmId = this.selectedFirm;

      this.isLoading = true;

      if (this.selectedFirm) {
        await this.listTest(this.selectedFirm);

        const firm = this.firms.filter((x) => x._id === this.selectedFirm);
        this.dialog.firmName = firm[0].name;

        this.tests.forEach((item) => {
          this.test.push({
            _id: item.testId,
            testName: item.testName,
            maxCount: item.maxCount,
            usedCount: item.usedCount,
            remainingContour: item.maxCount - item.usedCount
          });
        });

        this.showTable = true;
      } else this.showTable = false;
    },
    showDialogContourRequest(item) {
      this.dialog.testName = item.testName;
      this.dialog.testId = item._id;
      this.dialogContour = true;
    },
    closeDialogContour() {
      this.dialogContour = false;
    },
    async saveDialogContourRequest() {
      if (this.dialog.requestContour !== null) {
        await axios
          .post('/update-firm-test-count', {
            firmId: this.dialog.firmId,
            testId: this.dialog.testId,
            count: parseInt(this.dialog.requestContour, 10)
          })
          .then(() => {
            this.$toast.success('Talep Onaylandı');
            this.dialogContour = false;
            this.dialog.requestContour = null;
            this.closeDialogContour();
            this.changeFirm(this.selectedFirm);
          })
          .catch((error) => {
            this.$toast.error(error.toString());
          });
      } else this.$toast.error('Lütfen Girdiyi Kontrol Edin');
    }
  }
};
</script>

<style>
.dialogButton {
  padding: 2px 4px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.dialogButton:hover {
  background-color: lightgray;
}
</style>
